/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($, _c, undefined) {
    'use strict';

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.

    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                // mobile menu
                if ($(window).width() < 768) {
                    $('.parent').find('.submenu').removeClass('submenu').addClass('mobile-submenu');
                    $('.parent').find('.resources-submenu').removeClass('resources-submenu');
                }

                // mobile menu drop down
                if ($(window).width() < 769) {
                    $('.topnav > ul > .parent').append('<span class="ico"></span>');
                    $(window).on('load', function () {
                        $('.parent .ico').on('click', function () {
                            if ($(this).parent().hasClass('open')) {
                                $(this).parent().removeClass('open');
                                $(this).prev().slideUp(400);
                            } else {
                                $(this).parent().addClass('open');
                                $(this).prev().slideDown(400);
                            }
/*
                            if ($(this).parent().find('.mobile-submenu').hasClass('active')) {
                                // if the sub menu already has the class 'active' / is already opened
                                $('.mobile-submenu').slideUp(500); // then slide up
                                $('.mobile-submenu').removeClass('active'); // and remove 'active' class
                                $('.parent').find('a').removeClass('active');
                            } else { // otherwise if the submenu does not have 'active' class then it is not open
                                $('.mobile-submenu').slideUp(500); // so slide up all the other opened sub menus
                                $('.parent').find('a').removeClass('active');
                                $(this).find('.mobile-submenu').slideDown(500).addClass('active'); // and open this one and add class 'active' to it
                                $(this).find('a').addClass('active');
                            }*/
                        });
                    });
                }

                function resourcesMenu() {
                    if ($('.topnav > ul').length > 0) {
                        var menu_left = $('.topnav > ul').offset().left;
                        var menu_item_left = $('.topnav > ul .resources-dd').offset().left;
                        var set_left = menu_item_left - menu_left;
                        $('.resources-submenu').css('left', -Math.abs(set_left) + 'px');
                    }
                }
                resourcesMenu();

                $(window).on('resize', function (event) {
                    event.preventDefault();
                    resourcesMenu();
                });

                $('.hamburger').on('click', function () {
                    $(this).toggleClass('is-active');
                    if ($(this).hasClass('is-active')) {
                        $('.topnav').slideDown(500);
                    } else {
                        $('.topnav').slideUp(500);
                    }
                });


                // Scroll To
                (function () {
                    $('.scrollto').on('click', function (e) {
                        e.preventDefault();
                        var section = $(this).attr('href');
                        $('html, body').animate({
                            scrollTop: $(section).offset().top
                        }, 1000);
                    });
                })();

                // Forms
                $('input:file').each(function () {
                    var $input = $(this),
                        $label = $input.parent(),
                        labelVal = 'Select File';

                    $input.on('change', function (e) {
                        var fileName = '';
                        if (this.files && this.files.length > 1) {
                            fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
                        } else if (e.target.value) {
                            fileName = e.target.value.split('\\').pop();
                        }
                        if (fileName) {
                            $label.find('span').html(fileName);
                        } else {
                            $label.find('span').html(labelVal);
                        }
                    });

                    $input
                        .on('focus', function () {
                            $input.addClass('has-focus');
                        })
                        .on('blur', function () {
                            $input.removeClass('has-focus');
                        });
                });

                (function () {
                    //Disable for certain gravity form inputs
                    //Selectric should be disabled since it causes conflicts with filling out forms.
                    $('select:not(#input_67_7, #input_24_2, #input_65_7_4, #input_67_1, #input_67_2, #input_67_22, #input_67_16, #input_67_6, #input_67_4)').selectric();
                })();

                // Change arrow link arrow image on <hover></hover>
                if ($(window).width() > 768) {
                    $('.hero-text > a').on('mouseenter', function () {
                        $(this).children('img').attr('src', '/wp-content/themes/eiia-theme/dist/images/arrow-white.svg');
                    });
                    $('.hero-text > a').on('mouseleave', function () {
                        $(this).children('img').attr('src', '/wp-content/themes/eiia-theme/dist/images/arrow-gold.svg');
                    });

                    $('.box-header > a').on('mouseenter', function () {
                        $(this).children('img').attr('src', '/wp-content/themes/eiia-theme/dist/images/arrow-black.svg');
                    });
                    $('.box-header > a').on('mouseleave', function () {
                        $(this).children('img').attr('src', '/wp-content/themes/eiia-theme/dist/images/arrow-grey.svg');
                    });

                    $('.arrow-link').on('mouseenter', function () {
                        $(this).children('img').attr('src', '/wp-content/themes/eiia-theme/dist/images/arrow-black.svg');
                    });
                    $('.arrow-link').on('mouseleave', function () {
                        $(this).children('img').attr('src', '/wp-content/themes/eiia-theme/dist/images/arrow-grey.svg');
                    });
                }


                // Track Resource clicks
                $('.click-count').on('click', function () {
                    $(this).toggleClass("favorite");
                    $.post({
                        url: "/data/contacts/v1/incrementcount",
                        data: {
                            'id': $(this).data("id"),
                        },
                        success: function (data) {
                            console.log(data);
                        }
                    });
                })

                // toggle links
                if ($('.toggle-links').length > 0) {
                    $('.toggle-links .toggle-link a').on('click', function (e) {
                        e.preventDefault();
                        if (!$(this).hasClass('disabled')) {
                            if ($(this).parent().hasClass('active')) {
                                $(this).parent().removeClass('active');
                                $(this).parent().next().slideUp(300);
                            } else {
                                $(this).parent().addClass('active');
                                $(this).parent().next().slideDown(300);
                            }
                        }
                    });
                }

                if ($('.gfield.disabled .selectric-wrapper select').length > 0) {
                    $('.gfield.disabled .selectric-wrapper select').prop('disabled', 'disabled');
                    $('.gfield.disabled .selectric-wrapper select').data('selectric').refresh();
                }

                $('#gform_21 input[type="reset"]').on('click', function () {
                    $('#gform_21 .validation_error').remove();
                    $('#gform_21 .gfield_error').removeClass('gfield_error');
                    $('#gform_21').trigger("reset");

                    return false;
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                //Favorite toggle state
                $('svg.star, a.star').bind('click', function (event, element) {
                    $(this).toggleClass("favorite");
                    $(this).siblings(".star").toggleClass('favorite');
                    var svg = $(this).children();

                    //There's prob a way more elegant way of doing this
                    if ($(this).siblings("a.star").hasClass("favorite") || $(this).hasClass("favorite")) {
                        $(this).siblings("a.star").text("Remove from bookmarks");
                        $(this).text("Remove from bookmarks");
                        $(this).append(svg);
                    } else {
                        $(this).siblings("a.star").text("Add to bookmarks");
                        $(this).text("Add to bookmarks");
                        $(this).append(svg);
                    }

                    $.ajax({
                        url: "/data/contacts/v1/syncfav?r=" + $(this).data("resource-id") + "&u=" + $(this).data("u"),
                        success: function (data) {
                            console.log(data);
                        }
                    });
                });

                // accordions
                $(".accordion-content:not(.start-open)").hide();

                $(".accordion-content.start-open").show();

                $('.accordion-title').on('click', function () {
                    $(this).parent('.accordion-category').find('.accordion').slideToggle();
                    $(this).toggleClass('active-category');
                });

                $(".accordion-control").click(function (event) {
                    event.preventDefault();

                    var $control = $(this);
                    var accordionID = $control.attr('aria-controls');
                    var $accordionContent = $("#" + accordionID);
                    var isAriaExp = $control.attr('aria-expanded');
                    if (isAriaExp == "true") {
                        $control.attr('aria-expanded', "false");
                    } else {
                        $control.attr('aria-expanded', "true");
                    }

                    var isAriaHid = $accordionContent.attr('aria-hidden');

                    if (isAriaHid) {
                        $accordionContent.attr('aria-hidden', "false");
                    } else {
                        $accordionContent.attr('aria-hidden', "true");
                    }

                    $accordionContent.slideToggle();
                });

                if ($('.program-info').length > 0) {
                    if ($(window).width() > 1023) {
                        var sidebar_height = $('#sidebar').children('.sidebar__inner').height();
                        var content_height = $('#content').height();
                        if (content_height > sidebar_height) {
                            var sidebar = new StickySidebar('#sidebar', {
                                containerSelector: '#main-content',
                                innerWrapperSelector: '.sidebar__inner',
                                topSpacing: 0,
                                bottomSpacing: 0
                            });
                        }
                    }
                }

                if ($('.datepicker').length > 0) {
                    $('.datepicker').mask('99/99/2099');
                }

                $('#commitments-filter .date').each(function(i, el) {
                    $(el).datepicker({
                        dateFormat: 'mm/dd/yy',
                        showOn: 'button',
                        buttonImage: $(el).data('img'),
                        buttonImageOnly: true,
                        buttonText: 'Select date',
                        changeMonth: true,
                        changeYear: true,
                    });
                });

                // Watch for GF dropdowns change
                $('.rm-service-plan-update select, .rm-plan-entry select, #commitments-filter select').selectric('destroy');
                $('.rm-service-plan-update, .rm-plan-entry').each(function(el) {
                    $('.risk-manager select', el).on('change', function() {
                        $('body').addClass('loading');
                        // Load institutions
                        $.ajax({
                            url: ajax.url,
                            type: 'GET',
                            data: {
                                action: 'load_institutions_list',
                                manager: $(this).val()
                            },
                            success: function (response) {
                                $('.institution .gfield_select', $('.rm-service-plan-update, .rm-plan-entry'))
                                    .html(response)
                                    //.data('selectric').refresh();

                                $('.commitment .gfield_select', $('.rm-service-plan-update, .rm-plan-entry'))
                                    .html('<option value="">- Select Commitment -</option>')
                            },
                            complete: function () {
                                $('body').removeClass('loading');
                            }
                        });
                    });

                    $('.institution select', el).on('change', function() {
                        // Load commitments
                        $('body').addClass('loading');
                        $.ajax({
                            url: ajax.url,
                            type: 'GET',
                            data: {
                                action: 'load_commitments',
                                manager: $('.risk-manager select').val(),
                                institution: $(this).val()
                            },
                            success: function (response) {
                                $('.commitment .gfield_select', $('.rm-service-plan-update, .rm-plan-entry'))
                                    .html(response)
                                    //.data('selectric').refresh();
                            },
                            complete: function () {
                                $('body').removeClass('loading');
                                $('body.risk-management-service-plan-update .gform_fields .gfield.critical_elements .gfield_checkbox').html('<li><strong>There are no critical elements.</strong></li>');
                            }
                        });
                    });

                    var criticalElemTemplate = '';
                    if ($('body.risk-management-service-plan-update .gform_fields .gfield.critical_elements').length > 0) {
                        criticalElemTemplate = $('body.risk-management-service-plan-update .gform_fields .gfield.critical_elements .gfield_checkbox > li').clone();
                    }

                    $('.commitment select', el).on('change', function() {
                        // Load commitments
                        if ($(this).val() > 0) {
                            $('body').addClass('loading');
                            $.ajax({
                                url: ajax.url,
                                type: 'GET',
                                data: {
                                    action: 'load_commitment_details',
                                    commitment: $(this).val()
                                },
                                success: function (response) {
                                    let data = JSON.parse(response);

                                    $('.due_date input.datepicker').val(data.due_date);
                                    $('.completion_date input.datepicker').val(data.completion_date);

                                    $('.commitment_type input').val(data.commitment_type);
                                    $('.commitment_type input').trigger('change');

                                    let list = [];
                                    if (Object.keys(data.critical_elements).length > 0) {
                                        let i = 1;
                                        for (const [key, value] of Object.entries(data.critical_elements)) {
                                            let item = $(criticalElemTemplate).clone();
                                            if ($(criticalElemTemplate).length > 0) {
                                                $(item).find('input').val(key).attr('id', key);
                                                $(item).find('input').attr('name', $(item).find('input').attr('name', ).replace('.1', '.' + i.toString()));

                                                if (parseInt(value.state) > 0) {
                                                    $(item).find('input').attr('checked', 'checked');
                                                }

                                                $(item).find('label').html(value.label).attr('for', key);

                                                list.push($(item).prop('outerHTML'));
                                            }
                                            i++;
                                        }
                                    } else {
                                        list.push('<li><strong>There are no critical elements.</strong></li>');
                                    }

                                    $('body.risk-management-service-plan-update .gform_fields .gfield.critical_elements .gfield_checkbox').html(list.join('\t'));

                                    // $('.date input.datepicker').val(data.date);

                                    // Leave next fields empty
                                    // $('.communication_type .gfield_select').val('');
                                    // $('.contact input').val('');
                                    // $('.summary textarea').val('');

                                    // if ($('.communication_type .gfield_select').length > 0) {
                                    //     $('.communication_type .gfield_select').val(data.communication_type);
                                    // }
                                    // $('.contact input').val(data.contact);
                                    // $('.summary textarea').val(data.summary);
                                },
                                complete: function () {
                                    $('body').removeClass('loading');
                                }
                            });
                        } else {
                            $('body.risk-management-service-plan-update .gform_fields .gfield.critical_elements .gfield_checkbox').html('<li><strong>There are no critical elements.</strong></li>');
                        }
                    });

                    $('body.page-template-template-rm-mobile-update .print-report').on('click', function(e) {
                        e.preventDefault();
                        $('#download-export').removeClass('hidden').show();
                    });

                    $('#download-export a.close').on('click', function(e) {
                        e.preventDefault();

                        $('#download-export').addClass('hidden').hide();
                    });

                    $('a.remove-commitment').on('click', function() {
                        if (window.confirm('Are you sure you wish to delete commitment \'' + $(this).data('text') + '\'?')) {
                            $('body').addClass('loading');
                            $.ajax({
                                url: ajax.url,
                                type: 'GET',
                                data: {
                                    action: 'remove_commitment',
                                    commitment: $(this).data('id')
                                },
                                success: function(response) {
                                    if (response == 'success') {
                                        window.location.href = window.location.href;
                                    } else {
                                        console.log('Can not remove commitment');
                                    }
                                },
                                complete: function() {
                                    $('body').removeClass('loading');
                                }
                            });
                        }
                    });
                });
            }
        },

        // Sign In
        'sign_in': {
            init: function () {
                /*if ($("[for='user_login']").length > 0) {
                    var formChildren = $("[for='user_login']").children();
                    $("[for='user_login']").html("Email Address");
                    $("[for='user_login']").append(formChildren);
                }*/
				if($('#user_login').length){
					var el = $('#user_login').detach();
					$('.login-username label').append(el);
				}
				
				if($('#user_pass').length){
					var el = $('#user_pass').detach();
					$('.login-password label').append(el);
				}
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },

        // Home page
        'page_template_template_home': {
            init: function () {
                var waypoint1 = new Waypoint({
                    element: document.getElementById('section_id'),
                    handler: function (direction) {
                        if (direction === 'down') {
                            $('.col-left').addClass('fadeInUp');
                        }
                    },
                    offset: '77%'
                });

                var waypoint2 = new Waypoint({
                    element: document.getElementById('section_id'),
                    handler: function (direction) {
                        if (direction === 'down') {
                            $('.col-right').addClass('fadeInUp');
                        }
                    },
                    offset: '77%'
                });

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },

        // Contact Page
        'page_template_template_contact': {
            init: function () {

                // checks to see if email is valid. If it's valid turns floating label text to gray, if not valid turns floating label text to red
                function emailValid() {
                    // putting the value of the email field into a variable
                    var email = $('input[type="email"]').val();
                    //if there isn't a '@' symbol in the email, email is invalid and turns floating label text to red
                    if (email.indexOf('@') === -1) {
                        $('.float-label').css('color', 'red');
                        //if there is a '@' symbol in the email, email is valid and turns floating label text to gray
                    } else if (email.indexOf('@') > -1) {
                        $('.float-label').css('color', 'gray');
                    }
                };

                // Everytime a key is pressed check to see if the email is invalid and create the floating label
                $('input[type="email"]').on('keyup', function () {
                    emailValid();
                    // If there is not already a floating label then create one.
                    if ($('.float-label').length === 0) {
                        $('.ginput_container_email').prepend('<label class="float-label">Email Address</label>');
                        //if the email value is empty then remove the floating label
                    } else if ($(this).val().length === 0) {
                        $('.float-label').remove();
                        emailValid();
                    }
                })

                $(document).on('gform_post_render', function (event, formID) {
                    (function () {
                        $('select').selectric();
                    })();
                });

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

                $('.program-field label').attr('id', 'program-field')
                $('.program-field .selectric-wrapper .selectric-input').attr('aria-labelledby', 'program-field');
                $('.topic-field label').attr('id', 'topic-field')
                $('.topic-field .selectric-wrapper .selectric-input').attr('aria-labelledby', 'topic-field');

            }
        },

        // Resources Page
        'page_template_template_resources': {
            init: function () {

                // If window width is 568px or smaller then close the search accordion
                if ($(window).width() < 568) {
                    $('.accordion-content:not(.fave-filter)').removeClass('start-open');
                    $('.accordion-control').attr('aria-expanded', "false");
                }

                //Add value to filter list
                $('input[type="checkbox"]').on('click', function () {
                    // if checkbox is checked
                    if ($(this).prop('checked') == true) {
                        // put the value of the checkbox into the "value" variable
                        let value = $(this).next('label').text();

                        // turn selected checkbox label font weight to bold
                        $(this).next('label').css({
                            'font-weight': 'bold',
                            'opacity': '1'
                        });

                        // append the value in the filter list
                        $('.filtered-results').append('<span class="filtered-value">' + value + '</span>');

                        // if user unchecks checkbox
                    } else if ($(this).prop('checked') == false) {

                        // store that value into the variable 'value'
                        let value = $(this).next('label').text();

                        // turn selected checkbox label font weight to normal
                        $(this).next('label').css({'font-weight': 'normal', 'opacity': ''});


                        // find the span that contains the value and remove it from the filter list.
                        $('.filtered-results').find("span:contains(" + value + ")").remove();
                    }
                });

                // Remove value and uncheck its checkbox if user clicks on the filtered value
                $('.filtered-results').on('click', '.filtered-value', function () {
                    // because its a dynamic element that was added via code, have to add it to target the pararent on click and add the element to the parameter

                    let value = $(this).text();
                    // store the clicked filtered value inside a variable called 'value'

                    // finds the filter value and removes it from the filtered list
                    $('.filtered-results').find(".filtered-value:contains(" + value + ")").remove();

                    // finds the checkbox associated with that filtered value and unchecks it
                    $('.checkbox').find("label:contains(" + value + ")").prev('input[type="checkbox"]').prop('checked', false);
                    $('.checkbox').find("label:contains(" + value + ")").css({'font-weight': 'normal', 'opacity': ''});

                    //Reload page
                    setTimeout($('form#resource-search').submit(), 500);
                });


                //Keep filter results up on page reload
                $('#resource-search input[type="checkbox"]').each(function () {
                    if (this.checked) {
                        // put the value of the checkbox into the "value" variable
                        let value = $(this).next('label').text();

                        // turn selected checkbox label font weight to bold
                        $(this).next('label').css({
                            'font-weight': 'bold',
                            'opacity': '1'
                        });

                        // append the value in the filter list
                        $('.filtered-results').append('<span class="filtered-value">' + value + '</span>');
                    }
                });

                // fav checkbox
                $(window).on('load', function () {
                    if (document.getElementById('favorites').checked) {
                        $('.fave-filter label').addClass('active');
                    } else {
                        $('.fave-filter label').removeClass('active');
                    }
                });

                // See More / Show Less
                var num2 = $('#row-2').find('li').length;
                var text2More = 'See ${num2} more';
                var text2Less = 'See ${num2} less';
                $('.see-more > .first').text(text2More);
                $('.see-more > .first').on('click', function () {
                    if ($('.first').text() === text2More) {
                        $('.first').text(text2Less);
                    } else {
                        $('.first').text(text2More);
                    }
                });

                // See More / Show Less
                var num3 = $('#row-4').find('li').length;
                var text3More = 'See ${num3} more';
                var text3Less = 'See ${num3} less';
                $('.see-more > .second').text(text3More);
                $('.see-more > .second').on('click', function () {
                    if ($('.second').text() === text3More) {
                        $('.second').text(text3Less);
                    } else {
                        $('.second').text(text3More);
                    }
                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                //Get resource link param and check the boxes with matching names
                //var urlParams = new URLSearchParams(window.location.search);

                //URL Params polyfill
                $.urlParams = function (name) {
                    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
                    if (results == null) {
                        return null;
                    } else {
                        return decodeURI(results[1]) || 0;
                    }
                };

                $('#resource-search input[type=checkbox]').on('change', function (event) {
                    setTimeout($('#resource-search').submit(), 500);
                });

                if ($.urlParams('type') || $.urlParams('department')) {
                    $('#resource-search input[type="checkbox"]').each(function () {
                        if ($(this).data("f-slug") === $.urlParams('type')) {
                            $(this).prop('checked', true);
                            $(this).next('label').css({'font-weight': 'bold', 'opacity': '1'});
                        }

                        if ($(this).data("f-slug") === $.urlParams('department')) {
                            $(this).prop('checked', true);
                            $(this).next('label').css({'font-weight': 'bold', 'opacity': '1'});
                        }
                    });
                }

                $('input#reset-filter').bind('click', function (event) {
                    $('#resource-search').find('input:radio, input:checkbox').prop('checked', false);
                    $('#resource-search').find('input[name=re_search]').val('');
                    $('select#re_sort').val('sort_by');
                    $('select#re_page_numb').val('12');
                    $('select#re_file_type').val('all');
                });

                $('select#re_page_numb').on('selectric-change', function (event, element, selectric) {
                    $('input[name=page_num]').val($(this).val());
                });

                $('select#re_sort').on('selectric-change', function (event, element, selectric) {
                    $('input[name=sort]').val($(this).val());
                });

                $('select#re_file_type').on('selectric-change', function (event, element, selectric) {
                    $('input[name=file_type]').val($(this).val());
                });

                $('.sort-controls select').on('selectric-change', function (event, element, selectric) {
                    setTimeout($('form#resource-search').submit(), 500);
                });

                $('.sort-controls .selectric-wrapper:nth-child(2) .selectric-input').attr('aria-labelledby', 'sort-by');
                $('.sort-controls .selectric-wrapper:nth-child(4) .selectric-input').attr('aria-labelledby', 'amount-page');
                $('.sort-controls .selectric-wrapper:nth-child(6) .selectric-input').attr('aria-labelledby', 'file-type');

            }
        },

        // Search Results Page
        'page_template_template_search': {
            init: function () {

                // If window width is 568px or smaller then close the search accordion
                if ($(window).width() < 568) {
                    $('.accordion-content').removeClass('start-open');
                    $('.accordion-control').attr('aria-expanded', "false");
                }

                //Search results filter for resources, page and events
                $('.search-filter input[type="checkbox"]').on('click', function () {
                    // if checkbox is checked
                    if ($(this).prop('checked') == true) {
                        // turn selected checkbox label font weight to bold
                        $(this).next('label').css({
                            'font-weight': 'bold',
                            'opacity': '1'
                        });

                        // if user unchecks checkbox
                    } else if ($(this).prop('checked') == false) {
                        // turn selected checkbox label font weight to normal
                        $(this).next('label').css({'font-weight': 'normal', 'opacity': ''});
                    }
                });

                //Add value to filter list
                $('#resource-search input[type="checkbox"]').on('click', function () {
                    // if checkbox is checked
                    if ($(this).prop('checked') == true) {
                        // put the value of the checkbox into the "value" variable
                        let value = $(this).next('label').text();

                        // turn selected checkbox label font weight to bold
                        $(this).next('label').css({
                            'font-weight': 'bold',
                            'opacity': '1'
                        });

                        // append the value in the filter list
                        $('.filtered-results').append('<span class="filtered-value">' + value + '</span>');

                        // if user unchecks checkbox
                    } else if ($(this).prop('checked') == false) {

                        // store that value into the variable 'value'
                        let value = $(this).next('label').text();

                        // turn selected checkbox label font weight to normal
                        $(this).next('label').css({'font-weight': 'normal', 'opacity': ''});


                        // find the span that contains the value and remove it from the filter list.
                        $('.filtered-results').find("span:contains(" + value + ")").remove();
                    }
                });

                // Remove value and uncheck its checkbox if user clicks on the filtered value
                $('.filtered-results').on('click', '.filtered-value', function () {
                    // because its a dynamic element that was added via code, have to add it to target the pararent on click and add the element to the parameter

                    let value = $(this).text();
                    // store the clicked filtered value inside a variable called 'value'

                    // finds the filter value and removes it from the filtered list
                    $('.filtered-results').find(".filtered-value:contains(" + value + ")").remove();

                    // finds the checkbox associated with that filtered value and unchecks it
                    $('.checkbox').find("label:contains(" + value + ")").prev('input[type="checkbox"]').prop('checked', false);
                    $('.checkbox').find("label:contains(" + value + ")").css({'font-weight': 'normal', 'opacity': ''});

                    //Reload page
                    setTimeout($('form#resource-search').submit(), 500);
                });

                //Check if checkbox is already checked and plop the filter


                // See More / Show Less
                var num2 = $('#row-2').find('li').length;
                var text2More = 'See ${num2} more';
                var text2Less = 'See ${num2} less';
                $('.see-more > .first').text(text2More);
                $('.see-more > .first').on('click', function () {
                    if ($('.first').text() === text2More) {
                        $('.first').text(text2Less);
                    } else {
                        $('.first').text(text2More);
                    }
                });

                // See More / Show Less
                var num3 = $('#row-4').find('li').length;
                var text3More = 'See ${num3} more';
                var text3Less = 'See ${num3} less';
                $('.see-more > .second').text(text3More);
                $('.see-more > .second').on('click', function () {
                    if ($('.second').text() === text3More) {
                        $('.second').text(text3Less);
                    } else {
                        $('.second').text(text3More);
                    }
                });

                //Keep filter results up on page reload
                $('#resource-search input[type="checkbox"]').each(function () {
                    console.log(this.checked);
                    if (this.checked) {
                        // put the value of the checkbox into the "value" variable
                        let value = $(this).next('label').text();

                        // turn selected checkbox label font weight to bold
                        $(this).next('label').css({
                            'font-weight': 'bold',
                            'opacity': '1'
                        });

                        // append the value in the filter list
                        $('.filtered-results').append('<span class="filtered-value">' + value + '</span>');
                    }
                });
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                $('#resource-search input[type=checkbox]').on('change', function (event) {
                    setTimeout($('#resource-search').submit(), 500);
                });

                $('input#reset-filter').bind('click', function (event) {
                    $('#resource-search').find('input:radio, input:checkbox').prop('checked', false);
                    $('#resource-search').find('input[name=re_search]').val('');
                    $('select#re_sort').val('sort_by');
                    $('select#re_page_numb').val('12');
                    $('select#re_file_type').val('all');
                });

                $('select#re_page_numb').on('selectric-change', function (event, element, selectric) {
                    $('input[name=page_num]').val($(this).val());
                });

                $('select#re_sort').on('selectric-change', function (event, element, selectric) {
                    $('input[name=sort]').val($(this).val());
                });

                $('select#re_file_type').on('selectric-change', function (event, element, selectric) {
                    $('input[name=file_type]').val($(this).val());
                });

                $('.sort-controls select').on('selectric-change', function (event, element, selectric) {
                    setTimeout($('form#resource-search').submit(), 500);
                });
            }
        },

        // TTR
        'page_template_template_target_topic_response': {
            init: function () {

                $('.page-title').css('color', '#8c8c8c');
                $('.page-title > span').css('color', '#0a355f');

                //Show the add modal
                $('.page-template-template-target-topic-response .controls .add').on('click', function (e) {
                    e.preventDefault();
                    //Clear hidden field
                    $('#input_62_8').val('');
                    $('.add-respondent').fadeIn(400);
                });

                $('.add-respondent .wrapper .close, .add-respondent::before').on('click', function (e) {
                    e.preventDefault();
                    //Reset the form to remove any old content
                    $('#gform_62 .validation_error').remove();
                    $('#gform_62 .gfield_error').removeClass('gfield_error');
                    $('#gform_62').trigger("reset");
                    $('.add-respondent').hide();
                });


                /*
                * TODO: Create click to close + ESC key to close modal
                 */

                /* //Keyboard close
                 $(document).keypress(function(e) {
                     console.log(e.which);
                     if(e.which === 27) {
                         // escape pressed
                         $('#input_62_8').val('');
                         $('.add-respondent').hide();
                     }
                 });*/

                //Edit model
                $('.page-template-template-target-topic-response .responder-item a.edit').on('click', function (e) {
                    e.preventDefault();
                    $('.add-respondent').fadeIn(400);
                    //Prepopulate everything
                    $('#input_62_1').val($(this).data("f-name"));
                    $('#input_62_2').val($(this).data("l-name"));
                    $('#input_62_3').val($(this).data("title"));
                    $('#input_62_4').val($(this).data("phone"));
                    $('#input_62_5').val($(this).data("email"));

                    //Fill checkboxes
                    var assignedCodes = $(this).data("codes");
                    var codeList = $('ul#input_62_7 li input');
                    if(typeof assignedCodes === "number"){
                        assignedCodes = assignedCodes.toString();
                    }
                    $.each(codeList, function (i, code) {
                        //Find this in a string to check the box; tilde searches the string and confirms true v false
                        if (~assignedCodes.indexOf($(code).val())) {
                            //Check the box
                            $(code).prop('checked', true)
                        } else {
                            $(code).prop('checked', false)
                        }
                    });

                    //Hidden field
                    $('#input_62_8').val($(this).data("entry"));
                });

                $('.add-respondent .wrapper .close').on('click', function (e) {
                    e.preventDefault();

                    $('.add-respondent').hide();
                });

                //Show the print modal
                $('.page-template-template-target-topic-response .controls .print').on('click', function (e) {
                    e.preventDefault();
                    $('.download-entries').fadeIn(400);
                });

                $('.download-entries .wrapper .close').on('click', function (e) {
                    e.preventDefault();
                    $('.download-entries').hide();
                });

                function ttrOffset() {
                    $('.responders .responder-item .ttrs .ttr').each(function () {
                        var width = $(this).width() / 4;
                        var offset = -Math.abs($(this).children('.tooltip').width() / 2);
                        var left = offset + width;
                        $(this).children('.tooltip').css('left', left + 'px');
                    });
                }

                ttrOffset();

                $(window).on('resize', function () {
                    ttrOffset();
                });

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

            }
        },

        // Dashboard
        'page_template_template_dashboard': {
            init: function () {

                //Quarterly report variable sending
                $('.page-template-template-dashboard.campus-conduct-hotline .report-form select#input_63_3').on('change', function (e) {
                    $('input#input_63_5').val($(this).val());
                });

                gform.addFilter('gform_datepicker_options_pre_init', function (optionsObj, formId, fieldId) {
                    if (formId == 22) {
                        var maxDate;
                        var recent_quarter_index = -1;
                        var today = new Date();
                        var quarters = ['03/31', '06/30', '09/30', '12/31'];
                        quarters.forEach(function (date) {
                            var fullDate = new Date(date + '/' + new Date().getFullYear());
                            if (fullDate <= today) {
                                recent_quarter_index++;
                            }
                        });
                        if (recent_quarter_index < 0) {
                            maxDate = quarters[3] + '/' + (new Date().getFullYear() - 1);
                        } else {
                            maxDate = quarters[recent_quarter_index] + '/' + new Date().getFullYear();
                        }
                        optionsObj.maxDate = new Date(maxDate);
                        optionsObj.defaultDate = new Date(maxDate);
                    }
                    return optionsObj;
                    // if ( formId == 12 && fieldId == 16 ) {
                    //     var ranges = [
                    //         { start: new Date('06/15/2014'), end: new Date('06/25/2014') },
                    //         { start: new Date('07/15/2014'), end: new Date('07/25/2014') },
                    //         { start: new Date('08/15/2014'), end: new Date('08/25/2014') }
                    //     ];
                    //     optionsObj.beforeShowDay = function(date) {
                    //         for ( var i=0; i<ranges.length; i++ ) {
                    //             if ( date >= ranges[i].start && date <= ranges[i].end ) return [true, ''];
                    //         }
                    //         return [false, ''];
                    //     };
                    //     optionsObj.minDate = ranges[0].start;
                    //
                    // }
                    // return optionsObj;
                });

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

                setTimeout(function () {
                    $('.enrollment-upload-box .selectric-wrapper .selectric-input').attr('aria-labelledby', 'select-category');
                    $('.enrollment-upload-box #filter-institute + .selectric-wrapper .selectric-input').attr('aria-labelledby', 'filter-institute');
                    $('.student-file-upload #field_1_1 label').attr('id', 'student-file-upload');
                    $('.student-file-upload input[type="file"]').attr('aria-labelledby', 'student-file-upload');

                }, 500);

                if ($('#institute-name').length > 0) {
                    $('#enrollment-upload-form').on('submit', function (e) {
                        if ($('#institute-name').val() === null) {
                            e.preventDefault();
                        }
                    });
                }
            }
        },

        // Governance
        'page_template_template_governance': {
            init: function () {

                var breadCrumb = "Governance > ";
                if ($('.page-title').text().indexOf(breadCrumb) > -1) {
                    $('.page-title').css('color', '#8c8c8c');
                    $('.page-title > span').css('color', '#0a355f');
                }

                $('.download').on('mouseenter', function () {
                    $(this).parent('a').closest('.document').find('.pdf').attr('src', '/wp-content/themes/eiia-theme/dist/images/pdf-blue.svg');
                });
                $('.download').on('mouseleave', function () {
                    $(this).parent('a').closest('.document').find('.pdf').attr('src', '/wp-content/themes/eiia-theme/dist/images/pdf.svg');
                });

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                $('select#meeting-filter').on('selectric-change', function (event, element, selectric) {
                    setTimeout($('form#meeting-time-filter').submit(), 500);
                });

                $('.event-list-header .selectric-wrapper .selectric-input').attr('aria-labelledby', 'sort-by');

            }
        },

        // Program Info
        'page_template_template_program_info': {
            init: function () {

                var breadCrumb = "Program Info > ";
                if ($('.page-title').text().indexOf(breadCrumb) > -1) {
                    $('.page-title').css('color', '#8c8c8c');
                    $('.page-title > span').css('color', '#385E9D');
                }


            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },

        // Program Info
        'page_template_template_calendar_php': {
            init: function () {

                $('.label-tribe-bar-date').text('Search events from selected date');

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

                $('#tribe-bar-views .selectric-wrapper .selectric-input').attr('aria-labelledby', 'tribe-bar-views-label');
            }
        },

        'page_template_template_settings': {
            init: function () {
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
                $('#password-change').on('submit', function (e) {
                    e.preventDefault();
                    $('.error').css('display', 'none');

                    var change_password = $('[name="change_password"]').val();
                    var confirm_change_password = $('[name="confirm_change_password"]').val();

                    if (change_password !== confirm_change_password) {
                        $('.error.confirm-password').css('display', 'block');
                    } else if (change_password.length < 5) {
                        $('.error.password-length').css('display', 'block');
                    } else {
                        e.currentTarget.submit();
                        console.log(e);
                    }
                });

                jQuery(document).on('gform_confirmation_loaded', function(event, formId){
                    if (formId === 3) {
                        setTimeout(function() {
                            window.location.reload();
                        }, 1500);
                    }
                });
            }
        },

        // RM Mobile
        'page_template_template_rm_mobile': {
            init: function () {

                $('select').selectric('destroy');

                $('.page-title').css('color', '#8c8c8c');
                $('.page-title > span').css('color', '#0a355f');

                //Show the add modal
                $('.page-template-template-rm-mobile .controls .add-consultation').on('click', function (e) {
                    e.preventDefault();
                    //Clear hidden field
                    $('input#input_71_19').val(''); //Making a new entry, so clear Post ID
                    $('#edit-entry').fadeIn(200);
                });

                $('.add-respondent .wrapper .close, .add-consultation::before').on('click', function (e) {
                    e.preventDefault();
                    //Reset the form to remove any old content
                    $('#gform_71 .validation_error').remove();
                    $('#gform_71 .gfield_error').removeClass('gfield_error');
                    $('#gform_71').trigger("reset");
                    $('#edit-entry').hide();
                });


                /*
                * TODO: Create click to close + ESC key to close modal
                 */

                /* //Keyboard close
                 $(document).keypress(function(e) {
                     console.log(e.which);
                     if(e.which === 27) {
                         // escape pressed
                         $('#input_62_8').val('');
                         $('.add-respondent').hide();
                     }
                 });*/


                /*
                * Open and close modals
                 */
                //Edit model
                $('.page-template-template-rm-mobile .responder-item a.edit').on('click', function (e) {

                    e.preventDefault();
                    $('#edit-entry').fadeIn(200);

                    //Prepopulate everything
                    //Text boxes
                    $('#edit-entry input#input_72_3').val($(this).data("date"));
                    $('#edit-entry input#input_72_10').val($(this).data("time-hours"));
                    $('#edit-entry select#input_72_23').val($(this).data("time-minutes")).change();
                    $('#edit-entry input#input_72_18').val($(this).data("contact"));
                    $('#edit-entry textarea#input_72_14').text($(this).data("summary"));
                    $('#edit-entry select#input_72_1').val($(this).data("director")).change();

                    //All selects
                    $('#edit-entry select#input_72_22').val($(this).data("source-dept"));
                    $('#edit-entry select#input_72_4').val($(this).data("type")).change();
                    $('#edit-entry select#input_72_16').val($(this).data("purpose")).change();
                    $('#edit-entry select#input_72_6').val($(this).data("detail")).change();
                    $('#edit-entry select#input_72_2').val($(this).data("institution")).change();

                    //Hidden field
                    $('#edit-entry input#input_72_19').val($(this).data("entry")); //Should be Post ID of currently
                    // edited entry
                });

                $('#edit-entry .wrapper .close').on('click', function (e) {
                    e.preventDefault();

                    $('#edit-entry').hide();
                });

                //Show the print / reports modal
                $('.page-template-template-rm-mobile .controls .print-report').on('click', function (e) {
                    e.preventDefault();
                    $('#download-export').fadeIn(400);
                });

                $('#download-export .wrapper .close').on('click', function (e) {
                    e.preventDefault();
                    $('#download-export').hide();
                });

                //For the Purpose + Purpose Type linked dropdowns, auto select the correct Purpose Detail section depending on Purpose
                $('select#input_71_16').change(function () {

                    var optionSelected = $(this).find("option:selected");
                    var textSelected   = optionSelected.text();

                    $('select#input_71_6 optgroup').not('optgroup[label="'+ textSelected +'"]').hide();
                    $('select#input_71_6 optgroup[label="'+ textSelected +'"], select#input_71_6 optgroup[label="'+ textSelected +'"]').show();
                });

                //For the Purpose + Purpose Type linked dropdowns, auto select the correct Purpose Detail section depending on Purpose
                $('select#input_72_16').change(function () {

                    var optionSelected = $(this).find("option:selected");
                    var textSelected   = optionSelected.text();

                    $('select#input_72_6 optgroup').not('optgroup[label="'+ textSelected +'"]').hide();
                    $('select#input_72_6 optgroup[label="'+ textSelected +'"], select#input_72_6 optgroup[label="'+ textSelected +'"]').show();
                });


                /*
                * Forced styling? Feel free to remove / change.
                 */
                function ttrOffset() {
                    $('.responders .responder-item .ttrs .ttr').each(function () {
                        var width = $(this).width() / 4;
                        var offset = -Math.abs($(this).children('.tooltip').width() / 2);
                        var left = offset + width;
                        $(this).children('.tooltip').css('left', left + 'px');
                    });
                }
                ttrOffset();

                $(window).on('resize', function () {
                    ttrOffset();
                });

            },
            finalize: function () {
              // JavaScript to be fired on the home page, after the init JS
              $(document).on('change', '#add-entry .director select', function() {
                var obj = $(this).parents('.rm-mobile-add-consultation');
                $.get(searchwp_live_search_params.ajaxurl, {
                  action: 'load_institutions',
                  director: $(this).val()
                }, function (data) {
                  $('.institution select', obj).html(data);
                });
              });

              //Filter only on initial page load
              $('.rm-mobile-add-consultation .director select').trigger('change');
            }
        },

        'page_template_template_rm_mobile_update': {
            init: function () {
                $('input, select', $('form#commitments-filter')).on('change', function() {
                    $('form#commitments-filter').submit();
                });

                $(document).on('gform_post_render', function() {
                    $('.gf_readonly input').attr('readonly', 'readonly').attr('disabled', 'disabled');
                });
            },
            finalize: function () {
            }
        },

        'page-template-template-rm-mobile-entry': {
            init: function () {
            },
            finalize: function () {
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(window.jQuery, window.Clique); // Fully reference jQuery after this point.
